import { TopicResourceType } from '@flipgrid/flipkit';

import type { Focus } from 'types';

export const isAdobeSparkEmbeddable = (topicFocus?: Focus) => {
  if (!topicFocus || !topicFocus.type) return false;
  const { type } = topicFocus;
  return (
    type === TopicResourceType.ExternalLink &&
    (topicFocus.resource.indexOf('spark.adobe.com/video') > -1 || topicFocus.resource.indexOf('adobe.io') > -1)
  );
};

/**
 *
 * @param {object} params Object
 * @description removes all keys that have a falsy value
 * @returns {URLSearchParams} Object
 */
export const urlSearchParamsSanitizer = (params: AnyObj) => {
  Object.keys(params).forEach(key => {
    if (params[key] === undefined || params[key] === null || !`${params[key]}`) delete params[key];
  });

  return new URLSearchParams(params as Record<string, string>);
};

export const mobileDeepLinkUrl = (vanityToken?: string, topicId?: string, gridToken?: string, canvasToken?: string) => {
  const params = urlSearchParamsSanitizer({
    action: 'record',
    code: vanityToken,
    topic: topicId,
    grid_token: gridToken,
    canvasToken,
  });

  return `flipgrid://${vanityToken}?${params}`; // This is intentionally set to flipgrid
};

export const signInUrl = (authServiceUrl: string, redirectUrl: string) =>
  `${authServiceUrl}/educator?redirect_url=${encodeURIComponent(redirectUrl)}`;
